import Card from '~/components/cards/Card.vue';
import Certificate from '~/components/Certificate.vue';
import SideScroll from '~/components/SideScroll.vue';
import WebpPicture from '~/components/WebpPicture.vue';
export default {
  __name: 'Slides',
  props: {
    slides: Array,
    locale: String,
    title: String,
    description: String
  },
  setup: function setup(__props) {
    var props = __props;
    var _useNuxtApp = useNuxtApp(),
      $i18n = _useNuxtApp.$i18n;
    var toDouble = function toDouble(d) {
      return d >= 10 ? d : "0".concat(d);
    };
    var locale = computed(function () {
      return $i18n.locale || 'uk';
    });
    var certData = computed(function () {
      var separatedMark = {
        theory: 88,
        practice: 100
      };
      var d = new Date();
      d = "".concat(toDouble(d.getDate()), ".").concat(toDouble(d.getMonth() + 1), ".").concat(toDouble(d.getFullYear()));
      var name = {
        uk: {
          "givenName": "Марічка",
          "familyName": "Смілива"
        },
        en: {
          "givenName": "Olivia",
          "familyName": "Smart"
        }
      };
      return {
        givenName: name[locale.value].givenName,
        familyName: name[locale.value].familyName,
        certDate: d,
        certId: 'AAA111',
        averageMark: 94,
        separatedMark: separatedMark,
        position: 234,
        courseName: 'Корисний і цікавий курс',
        courseNames: {},
        pattern: '/images/creative-practice/certificate/certificate-pattern-full.png',
        logo: '/images/creative-practice/certificate/cp-logo-s.svg',
        logoBg: '#0E1014',
        company: null
      };
    });
    return {
      __sfc: true,
      $i18n: $i18n,
      props: props,
      toDouble: toDouble,
      locale: locale,
      certData: certData,
      Card: Card,
      Certificate: Certificate,
      SideScroll: SideScroll,
      WebpPicture: WebpPicture
    };
  }
};