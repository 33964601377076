<template>
<svg
    :class="{round: type == 'legend'}"
    class="ActivityRings"
    viewBox="0 0 240 240"
>
    <g
        v-if="typeof circle1 !== 'undefined'"
        class="ring ring1"
        :class="[color1]"
        style="transform: scale(1) rotate(-90deg);"
    >
        <circle class="background"
            cx="50%" cy="50%"
            :stroke-width="radius[type].strokeWidth"
            :r="radius[this.type].circle1Radius" />
        <circle class="completed"
            cx="50%" cy="50%"
            :stroke-width="radius[type].strokeWidth"
            :r="radius[this.type].circle1Radius"
            :stroke-dasharray="circle1" />
    </g>

    <g
        v-if="typeof circle2 !== 'undefined'"
        class="ring ring2"
        :class="[color2]"
        style="transform: rotate(-90deg);"
    >
        <circle class="background"
            cx="50%" cy="50%"
            :r="radius[this.type].circle2Radius"
            :stroke-width="radius[type].strokeWidth" />
        <circle class="completed"
            cx="50%" cy="50%"
            :r="radius[this.type].circle2Radius"
            :stroke-width="radius[type].strokeWidth"
            :stroke-dasharray="circle2" />
    </g>

    <g
        v-if="typeof circle3 !== 'undefined'"
        class="ring ring3"
        :class="[color3]"
        style="transform: rotate(-90deg);"
    >
        <circle class="background"
            cx="50%" cy="50%"
            :r="radius[this.type].circle3Radius"
            :stroke-width="radius[type].strokeWidth" />
        <circle class="completed"
            cx="50%" cy="50%"
            :r="radius[this.type].circle3Radius"
            :stroke-width="radius[type].strokeWidth"
            :stroke-dasharray="circle3" />
    </g>

    <text
        v-if="type=='legend' && typeof circle1 !== 'undefined'"
        transform="matrix(1 0 0 1 120 16.916)" class="ring-value val-1">
        <tspan x="0" text-anchor="middle">{{ring1}}</tspan>
    </text>
    <text
        v-if="type=='legend' && typeof circle2 !== 'undefined'"
        transform="matrix(1 0 0 1 120 42.5016)" class="ring-value val-2">
        <tspan x="0" text-anchor="middle">{{ring2}}</tspan>
    </text>
    <text
        v-if="type=='legend' && typeof circle3 !== 'undefined'"
        transform="matrix(1 0 0 1 120 68.3701)" class="ring-value val-3">
        <tspan x="0" text-anchor="middle">{{ring3}}</tspan>
    </text>
    <text
        v-if="type!='simple'"
        transform="matrix(1 0 0 1 120 131.9874)" class="dials-total">
        <tspan x="0" text-anchor="middle">{{total}}</tspan>
    </text>
</svg>
</template>

<script>
export default {
    props: {
        ring1: Number,
        ring2: Number,
        ring3: Number,
        total: Number,
        type: {
            type: String,
            default: 'legend'
        },
        color1: String,
        color2: String,
        color3: String
    },


    data () {
        return {
            radius: {
                legend: {
                    circle1Radius: 108,
                    circle2Radius: 82,
                    circle3Radius: 56,
                    strokeWidth: 24
                },
                simple: {
                    circle1Radius: 108,
                    circle2Radius: 72,
                    circle3Radius: 36,
                    strokeWidth: 24
                },
                thin: {
                    circle1Radius: 108,
                    circle2Radius: 88,
                    circle3Radius: 68,
                    strokeWidth: 15
                },
            }
        }
    },

    computed: {
        circle1 () {
            let ring = this.ring1;
            if (typeof ring == 'undefined') return undefined;
            if (!ring || ring == NaN) ring = 0;
            return this.CalcDashArray(ring, this.radius[this.type].circle1Radius);
        },
        circle2 () {
            let ring = this.ring2;
            if (typeof ring == 'undefined') return undefined;
            if (!ring || ring == NaN) ring = 0;
            return this.CalcDashArray(ring, this.radius[this.type].circle2Radius);
        },
        circle3 () {
            let ring = this.ring3;
            if (typeof ring == 'undefined') return undefined;
            if (!ring || ring == NaN) ring = 0;
            return this.CalcDashArray(ring, this.radius[this.type].circle3Radius);
        }
    },

    methods: {
        CalcDashArray (val, radius) {
            let length = Math.round(2 * Math.PI * radius);
            return  [Math.round(length/100 * val),length];
        }
    }
}
</script>

<style>

</style>
