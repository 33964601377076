import "core-js/modules/es6.function.name.js";
// Add attribute 'menu-item' to elements that might be picked by arrow pressing
// inside modal element

export default {
  data: function data() {
    return {
      activeItem: null,
      items: []
    };
  },
  computed: {
    // Each modal component includes [open] computed value
    // Copy this value to avoid any conflict
    isOpen: function isOpen() {
      return this.open;
    },
    // Is component recently open
    active: function active() {
      return this.$modalityStore.logs[0] && this.$modalityStore.logs[0].type == this.modalType && this.$modalityStore.logs[0].name == this.modalName;
    },
    collapseStore: function collapseStore() {
      return useCollapseStore();
    }
  },
  watch: {
    isOpen: function isOpen(val) {
      var _this = this;
      if (val) {
        document.addEventListener('keydown', this.arrowNavigation);
        // give time to renter content
        setTimeout(function () {
          _this.getItems();
        }, 100);
      } else {
        document.removeEventListener('keydown', this.arrowNavigation);
        if (this.items[this.activeItem]) this.items[this.activeItem].blur();
        this.activeItem = null;
        this.items = [];
      }
    },
    activeItem: function activeItem(val) {
      if (this.items[val]) {
        var collapseId = this.items[val].getAttribute('menu-item-collapse');
        if (collapseId) this.collapseStore.add(collapseId);
      }
    }
  },
  methods: {
    getItems: function getItems() {
      if (!this.layoutId) return;
      this.items = document.querySelectorAll("#".concat(this.layoutId, " [menu-item]"));
    },
    arrowNavigation: function arrowNavigation(e) {
      if (!this.active || !this.items.length) return;

      // Update items list in case of dynamic menu change
      if (e.keyCode == 40 || e.keyCode == 38) this.getItems();
      if (e.keyCode == 40) {
        this.down();
        e.preventDefault();
      }
      if (e.keyCode == 38) {
        this.up();
        e.preventDefault();
      }
    },
    up: function up() {
      if (!this.activeItem) this.activeItem = this.items.length - 1;else this.activeItem--;
      this.items[this.activeItem].focus();
    },
    down: function down() {
      if (typeof this.activeItem != 'number' || this.activeItem == this.items.length - 1) {
        this.activeItem = 0;
      } else {
        this.activeItem++;
      }
      this.items[this.activeItem].focus();
    }
  },
  destroyed: function destroyed() {
    document.removeEventListener('keydown', this.arrowNavigation);
  }
};