<i18n>
{
    "uk": {
        "title": "Приклади наших уроків",

        "description": "Майже всі доступні освітні матеріали наша команда створює спеціально для платформи. Ми намагаємось робити їх максимально цікавими, якісними та насиченими, щоб ви могли швидко і з інтересом пройти навчання.",

        "video1": {
            "n": "Курс «Робота з Figma»",
            "d": "Фрагмент відеолекції «Робота з прямокутними фігурами в Figma»"
        },
        "video2": {
            "n": "Курс «Основи вебдизайну»",
            "d": "Фрагмент відеолекції «Історія вебдизайну»"
        },
        "video3": {
            "n": "Курс «UX/UI. Проєктування та дизайн інтерфейсів»",
            "d": "Фрагмент відеолекції «Що таке інтерфейс»"
        },
        "video4": {
            "n": "Курс «Робота з Photoshop»",
            "d": "Фрагмент відеолекції «Нейрофільтри»"
        },
        "video5": {
            "n": "Курс «Робота з After Effects»",
            "d": "Фрагмент відеолекції «Вступ до курсу»"
        },
        "video6": {
            "n": "Курс «Робота з Illustrator»",
            "d": "Фрагмент відеолекції «Огляд інтерфейсу»"
        },
        "video7": {
            "n": "Курс «Композиція в графічному дизайні»",
            "d": "Фрагмент відеолекції «Види композиції»"
        }
    },
    "en": {
        "title": "Examples of Our Lessons",

        "description": "Almost all available educational materials are created by our team specifically for the platform. We strive to make them as engaging, high-quality, and comprehensive as possible so that you can quickly and enthusiastically go through the learning process.",

        "video1": {
            "n": "Course 'Working with Figma'",
            "d": "Video lecture excerpt 'Working with Rectangles in Figma'"
        },
        "video2": {
            "n": "Course 'Fundamentals of Web Design'",
            "d": "Video lecture excerpt 'History of Web Design'"
        },
        "video3": {
            "n": "Course 'UX/UI. Interface Design'",
            "d": "Video lecture excerpt 'What is an Interface'"
        },
        "video4": {
            "n": "Course 'Working with Photoshop'",
            "d": "Video lecture excerpt 'Neural Filters'"
        },
        "video5": {
            "n": "Course 'Working with After Effects'",
            "d": "Video lecture excerpt 'Introduction to the Course'"
        },
        "video6": {
            "n": "Course 'Working with Illustrator'",
            "d": "Video lecture excerpt 'Interface Overview'"
        },
        "video7": {
            "n": "Course 'Graphic Design. Composition'",
            "d": "Video lecture excerpt 'Types of Composition'"
        }
    }
}
</i18n>


<template>
    <section>

        <div class="page-container">
            <div class="row mb-3 mb-md-4 ta-center">

                <div class="col-12 col-lg-10 offset-lg-1 ta-center">
                    <h2 class="h3 h2-md mt-0 mb-2 fw-800 fw-md-700">{{ title || $t('title', locale) }}</h2>
                    <div class="h3-md mt-2 fw-400" v-html="description || $t('description', locale)" />
                </div>

            </div>
        </div>

        <div class="sidescroll-compensate">
            <SideScroll class="d-flex flex-nowrap center-arrows video-ribbon">

                <Card
                    v-for="(video, i) in videosComputed"
                    :key="`video-${i}`"
                    class="video-item scroll-item p-2"
                >
                    <client-only>
                        <VimeoPlayer
                            ref="player"

                            :video-id="video.src"
                            :autoplay="optionsComputed.autoplay !== false"
                            :controls="vimeoControls"
                            :options="vimeoOptions"

                            class="video-wrapper mx-n2 mt-n2 mb-2"
                        />
                    </client-only>

                    <Component
                        :is="video.link ? 'nuxt-link' : 'div'"
                        :to="video.link ? localePath(video.link) : null"
                        class="video-data"
                        :class="{
                            'd-link': video.link
                        }"
                    >

                        <div class="course-icon mr-1">
                            <img
                                class="block-image"
                                :src="video.icon"
                                :alt="video.name"
                                width="32"
                                height="32"
                                loading="lazy"
                            />
                        </div>

                        <div class="video-info">
                            <div class="course-title">{{ video.name }}</div>
                            <div v-if="video.desc" class="video-desc f-small" v-html="video.desc" />
                        </div>

                    </Component>
                </Card>

            </SideScroll>
        </div>

    </section>
</template>


<script>
    import Card       from '~/components/cards/Card.vue';
    import SideScroll from '~/components/SideScroll.vue';

    import { vueVimeoPlayer } from 'vue-vimeo-player';

    export default {
        props: {
            videos:      Array,
            options:     Object,
            locale:      String,
            title:       String,
            description: String,
        },

        components: {
            Card,
            SideScroll,
            VimeoPlayer: vueVimeoPlayer,
        },

        data () {
            return {
                vimeoControls: true,
            };
        },

        computed: {
            videosComputed () {
                if (Array.isArray(this.videos) && this.videos.length > 0) return this.videos;

                return [
                    /* Commented out because it's in russian
                    {
                        name: this.$t('video1.n', this.locale),
                        icon: '/images/creative-practice/menu/icon-course-figma.svg',
                        desc: this.$t('video1.d', this.locale),
                        poster: '/images/creative-practice/posters/figma.png',
                        src: "509804437",
                        provider: "vimeo",
                    },
                    */
                    {
                        name: this.$t('video2.n', this.locale),
                        icon: "/images/creative-practice/menu/icon-course-webdesign.svg",
                        desc: this.$t('video2.d', this.locale),
                        link: '/creativepractice/course/webdesign-basics/about',
                        poster: '/images/creative-practice/webdesign-basics/lecture-03.png',
                        src: '507457514',
                        provider: 'vimeo',
                    },
                    {
                        name: this.$t('video3.n', this.locale),
                        icon: '/images/creative-practice/menu/icon-course-ux.svg',
                        desc: this.$t('video3.d', this.locale),
                        link: '/creativepractice/course/ux-ui-design-basics/about',
                        poster: '/images/creative-practice/webdesign-basics/lecture-03.png',
                        src: '559899898',
                        provider: 'vimeo',
                    },
                    {
                        name: this.$t('video4.n', this.locale),
                        icon: '/images/creative-practice/menu/icon-course-ps.svg',
                        desc: this.$t('video4.d', this.locale),
                        link: '/creativepractice/course/photoshop-basics/about',
                        poster: '/images/creative-practice/photoshop-basics/lecture-72.png',
                        src: '510262710',
                        provider: 'vimeo',
                    },
                    {
                        name: this.$t('video5.n', this.locale),
                        icon: '/images/creative-practice/menu/icon-course-ae.svg',
                        desc: this.$t('video5.d', this.locale),
                        link: '/creativepractice/course/after-effects-basics/about',
                        poster: '/images/creative-practice/after-effects-basics/module-1-lection-1.png',
                        src: '567473609',
                        provider: 'vimeo',
                    },
                    {
                        name: this.$t('video6.n', this.locale),
                        icon: '/images/creative-practice/menu/icon-course-ai.svg',
                        desc: this.$t('video6.d', this.locale),
                        link: '/creativepractice/course/illustrator-basics/about',
                        poster: '/images/creative-practice/webdesign-basics/lecture-03.png',
                        src: '660697541',
                        provider: 'vimeo',
                    },
                    {
                        name: this.$t('video7.n', this.locale),
                        icon: '/images/creative-practice/menu/icon-course-composition.png',
                        desc: this.$t('video7.d', this.locale),
                        link: '/creativepractice/course/design-basics-composition/about',
                        poster: '/images/creative-practice/design-basics-composition/module-1-thumb.png',
                        src: '507448242',
                        provider: 'vimeo',
                    }
                ];
            },

            optionsComputed () {
                if (this.options && typeof this.options == 'object') return this.options;

                return {
                    autoplay: false,
                    muted:    false,
                    loop:     true,
                };
            },

            vimeoOptions () {
                return {
                    color: this.optionsComputed.color || 'FFFFFF',
                    muted: this.optionsComputed.muted !== false,
                    loop:  this.optionsComputed.loop  !== false,
                    responsive: true,
                    texttrack: this.locale || 'uk',
                    byline: false,
                    portrait: false,
                    title: false,
                };
            },
        },
    };
</script>


<style lang="postcss" scoped>
    .sidescroll-compensate {
        margin: -20px 0;
    }

    .video-ribbon {
        @media screen and (min-width: 992px) {
            :deep(.side-scroll .scroll-item) {
                scroll-snap-align: center;
            }
        }
    }

    .video-item {
        white-space: normal;

        margin: 0 12px;

        display:         flex;
        flex-flow:       column nowrap;
        align-items:     stretch;
        flex:            1 0 calc(100% - 56px);
        min-width:       288px;

        overflow: hidden;

        @media screen and (min-width: 768px) {
            flex-basis: calc(100% - 72px);
        }

        @media screen and (min-width: 1200px) {
            flex-basis: 1032px;
        }

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .video-data {
        display:   flex;
        flex-flow: row nowrap;
    }

    .course-icon {
        margin-top: 4px;

        flex:   0 0 auto;
        width:  32px;
        height: 32px;

        > :deep(img) {
            border-radius: 6px;
        }
    }

    .course-title {
        font-weight: 700;
    }
</style>
