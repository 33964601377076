import "core-js/modules/es6.array.find-index.js";
import "core-js/modules/es6.array.filter.js";
export var useCollapseStore = defineStore({
  id: 'collapse',
  state: function state() {
    return {
      collapsed: []
    };
  },
  getters: {
    open: function open(state) {
      return function (id) {
        var index = state.collapsed.findIndex(function (o) {
          return o.id == id;
        });
        return index >= 0;
      };
    }
  },
  actions: {
    add: function add(id) {
      var index = this.collapsed.findIndex(function (o) {
        return o.id == id;
      });
      if (index < 0) this.collapsed.push({
        id: id
      });
    },
    remove: function remove(id) {
      var index = this.collapsed.findIndex(function (o) {
        return o.id == id;
      });
      if (index >= 0) this.collapsed.splice(index, 1);
    },
    toggle: function toggle(id) {
      var index = this.collapsed.findIndex(function (o) {
        return o.id == id;
      });
      if (index < 0) this.collapsed.push({
        id: id
      });else this.collapsed.splice(index, 1);
    },
    handleGroup: function handleGroup(payload) {
      var _this = this;
      var elements = this.collapsed.filter(function (o) {
        return o.group == payload.group && o.id != payload.id;
      });

      // Remove each with same group
      elements.forEach(function (el) {
        _this.remove(el.id);
      });

      // add group to current
      var index = this.collapsed.findIndex(function (o) {
        return o.id == payload.id;
      });
      if (index >= 0) this.collapsed[index].group = payload.group;
    }
  }
});