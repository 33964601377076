import "core-js/modules/es6.function.name.js";
import Card from '~/components/cards/Card.vue';
import Cinema from '~/components/Cinema.vue';
import SideScroll from '~/components/SideScroll.vue';
import WebpPicture from '~/components/WebpPicture.vue';
export default {
  props: {
    stories: Array,
    locale: String,
    title: String,
    description: String
  },
  components: {
    Card: Card,
    Cinema: Cinema,
    SideScroll: SideScroll,
    WebpPicture: WebpPicture
  },
  computed: {
    cinemaStore: function cinemaStore() {
      return useCinemaStore();
    },
    storiesComputed: function storiesComputed() {
      if (Array.isArray(this.stories) && this.stories.length > 0) return this.stories;
      return [{
        image: 'https://cdn.cases.media/image/original/cp-stories-viktoria-kozyk.png',
        preview: 'https://cdn.cases.media/image/original/cp-stories-preview-viktoria-kozyk-2.png',
        name: 'Вікторія Козик',
        portrait: '/images/creative-practice/stories/portrait-viktoria-kozyk.png',
        video: '878698145'
      }, {
        image: 'https://cdn.cases.media/image/original/cp-stories-iryna-mykhailenko.png',
        preview: 'https://cdn.cases.media/image/original/cp-stories-preview-iryna-mykhailenko-2.png',
        name: 'Ірина Михайленко',
        portrait: '/images/creative-practice/stories/portrait-iryna-mykhailenko.png',
        video: '878697702'
      }, {
        image: 'https://cdn.cases.media/image/original/cp-stories-valeriya-bosenko.png',
        preview: 'https://cdn.cases.media/image/original/cp-stories-preview-valeriya-bosenko-2.png',
        name: 'Валерія Босенко',
        portrait: '/images/creative-practice/stories/portrait-valeriya-bosenko.png',
        video: '878698056'
      }, {
        image: 'https://cdn.cases.media/image/original/cp-stories-andriy-shcherbinin.png',
        preview: 'https://cdn.cases.media/image/original/cp-stories-preview-andriy-shcherbinin.png',
        name: 'Андрій Щербінін',
        portrait: '/images/creative-practice/stories/portrait-andriy-shcherbinin.png',
        video: '882149528'
      }, {
        image: 'https://cdn.cases.media/image/original/cp-stories-andriy-bozhenko.png',
        preview: 'https://cdn.cases.media/image/original/cp-stories-preview-andriy-bozhenko.png',
        name: 'Андрій Боженко',
        portrait: '/images/creative-practice/stories/portrait-andriy-bozhenko.png',
        video: '882150234'
      }];
    },
    playlist: function playlist() {
      if (!this.storiesComputed) return [];
      var playlist = [];
      this.storiesComputed.forEach(function (story) {
        playlist.push({
          'type': 'video',
          'title': story.name,
          'poster': story.preview || story.image,
          'isVertical': true,
          'sources': [{
            'src': story.video,
            'provider': 'vimeo'
          }]
        });
      });
      return playlist;
    }
  },
  methods: {
    play: function play(index) {
      this.cinemaStore.on({
        playlist: this.playlist,
        index: index
      });
    }
  }
};