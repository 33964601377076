<template>
    <client-only>
        <portal
            to="limb"
            ref="portal"
            @hook:mounted="initPopper"
        >
            <div
                class="cases-dropdown"
                :class="classList"
                :id="ddId"
                v-on="listenMouseover ? {
                    mouseover: handlerover,
                    mouseleave: handlerleave
                } : {}
                "
            >
                <div
                    class="cases-dropdown-content"
                    :class="contentClasses"
                    :style="{width: width || bindedWidth}"
                    v-bsl="open && $mq == 'xs'"
                    :id="layoutId"
                    ref="content"
                >
                    <slot v-if="render" />
                </div>
            </div>
        </portal>
    </client-only>
</template>


<script>
import { createPopper } from '@popperjs/core';
import modalNavigation from '~/mixins/modalNavigation.js';

export default {
    mixins: [modalNavigation],

    name: 'CasesDropdown',

    props: [
        'modalName', 'classes', 'anchorId', 'placement',
        'listenMouseover', 'fallbackPlacements', 'contentClasses',
        'width', 'bindWidthId'
    ],

    data () {
        return {
            modalType: 'dropdown',
            emiter: 'cases-dropdown',
            popperInstance: null,
            contentSelector: 'main.page-content',
            render: false,
            bindedWidth: null
        }
    },

    computed: {
        layoutId () {
            return `${this.ddId}-layout`;
        },

        dd () {
            return this.$modalityStore.dropdowns.find(o => o.name == this.modalName);
        },

        open () {
            return !!this.dd;
        },

        classList () {
            let classList = this.classes || [];
            classList = classList.slice(0) // clone array
            if (this.open) classList.push('active');
            return classList
        },

        ddId () {
            return `dropdown-${this.modalName}`;
        },

        closableId () {
            return `[data-closable="${this.modalName}-dd"]`
        },

        ddAnchorId () {
            return this.dd && this.dd.anchorId || this.anchorId;
        },

        menuOpen () {
            return !!this.$modalityStore.menu;
        },

        container () {
            return document.querySelector(this.contentSelector)[0];
        }
    },

    watch: {
        open (newVal) {
            if (!process.browser) return;

            if (newVal) {
                this.render = true;
                this.updateWidth();

                this.$nextTick(() => {
                    this.initPopper();
                    this.$emit("show");
                    document.body.addEventListener('click', this.clickHandle)

                    if (this.$refs.content) this.$refs.content.scrollTop = 0;
                })
            } else {
                this.destroyPopper();
                this.$emit("hide");
                document.body.removeEventListener('click', this.clickHandle);

                setTimeout(() => {
                    if (!this.open) this.render = false;
                }, 1000)
            }
        },
    },

    mounted () {
        if (this.bindWidthId) {
            window.addEventListener('resize', this.updateWidth);
            this.updateWidth();
        }
    },

    methods: {
        updateWidth () {
            let element = this.bindWidthId;

            if (typeof this.bindWidthId == 'string') {
                element = document.getElementById(this.bindWidthId)
            }
            
            if (!element) return;

            this.bindedWidth = element.offsetWidth + 'px';
        },

        clickHandle (e) {
            if (!this.open) return;

            let excludedElement = e.target.closest(this.closableId);
            let ddEl = document.getElementById(this.ddId);

            if (
                !ddEl ||
                (!excludedElement && !(ddEl == e.target || ddEl.contains(e.target)))
            ) this.close();
        },

        close (e) {
            if (!this.open) return true;
            this.$modalityStore.closeDropdown({name: this.modalName});
        },

        initPopper () {
            if (this.$mq == 'xs') this.$cropContent();

            if (this.timeOut) clearTimeout(this.timeOut);

            let anchor   = document.getElementById(this.ddAnchorId);
            let dropdown = document.getElementById(this.ddId);
            if (!anchor || !dropdown) return;

            let modifiers = [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 8],
                    }
                }
            ]

            if (this.fallbackPlacements) {
                modifiers.push({
                    name: 'flip',
                    options: {
                        fallbackPlacements: this.fallbackPlacements
                    }
                })
            }

            this.popperInstance = createPopper(anchor, dropdown, {
                strategy: 'fixed',
                placement: this.placement || 'right-start',
                modifiers
            });

            this.popperInstance.forceUpdate();
        },

        destroyPopper () {
            this.timeOut = setTimeout( () => {
                if (this.popperInstance) {
                    this.popperInstance.destroy();
                    this.popperInstance = null;
                }
            }, 1000);
        },

        forceUpdate () {
            if (this.popperInstance) this.popperInstance.forceUpdate();
        },

        handlerover () { this.$emit('mouseover')},
        handlerleave () { this.$emit('mouseleave')}
    },

    beforeDestroy () {
        window.removeEventListener('resize', this.updateWidth);
    }
}
</script>
