import "core-js/modules/es6.regexp.replace.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.array.find.js";
import Babka from '~/components/thumbnails/BabkaThumbnail.vue';
import Dials from '~/components/Dials.vue';
import Dropdown from "~/components/CasesDropdown.vue";
import DdButton from '~/components/DdButton.vue';
export default {
  components: {
    Babka: Babka,
    Dials: Dials,
    Dropdown: Dropdown,
    DdButton: DdButton
  },
  props: ['course', 'defLink'],
  computed: {
    image: function image() {
      if (!this.course.thumbnail) return null;
      return this.course.thumbnail.replace('original', 'medium');
    },
    state: function state() {
      if (this.isFinished) return 'finished';
      if (this.isJoined) return 'joined';
      if (this.isBought) return 'bought';
      return 'none';
    },
    actions: function actions() {
      var actions = [];

      // Certificate
      if (this.state == 'finished' && this.card.certificateId) {
        actions.push({
          link: "/cert/".concat(this.card.certificateId),
          i18n: 'course.certificate',
          icon: 'icon-ic-certificate'
        });
      }

      // To current module
      if (this.state == 'joined' && this.currentModule) {
        actions.push({
          link: this.currentModuleLink,
          i18n: 'course.toCorrentModule',
          icon: 'icon-ic-play-stroke'
        });
      }

      // To course
      if (['joined', 'finished'].includes(this.state) && !this.course.awaiting) {
        actions.push({
          link: this.coursePageLink,
          i18n: 'course.toCourse',
          icon: 'icon-ic-graduation-stroke'
        });
      }

      // Enroll
      if (this.$auth.user && this.state == 'bought' && !this.course.awaiting) {
        actions.push({
          link: this.coursePageLink,
          i18n: 'course.signup',
          icon: 'icon-ic-flag'
        });
      }

      // About course
      if (this.course.hasLanding) {
        actions.push({
          link: this.courseLandingLink,
          i18n: 'course.moreAboutCourse',
          icon: 'icon-ic-info'
        });
      }
      return actions;
    },
    isFinished: function isFinished() {
      return this.card && this.card.finished;
    },
    isJoined: function isJoined() {
      return this.card && this.card.active;
    },
    isBought: function isBought() {
      return this.course.bought;
    },
    isFree: function isFree() {
      return this.isBought && !this.course.requiredAccess;
    },
    canBuy: function canBuy() {
      return this.course.blocks && this.course.blocks.subscribe;
    },
    canApply: function canApply() {
      return this.course.blocks && this.course.blocks.apply;
    },
    coursePageLink: function coursePageLink() {
      if (!this.course.awaiting) return "/creativepractice/course/".concat(this.course.slug);
      return false;
    },
    courseLandingLink: function courseLandingLink() {
      if (this.course.hasLanding) return "/creativepractice/course/".concat(this.course.slug, "/about");
      return false;
    },
    defaultLink: function defaultLink() {
      if (!this.course.awaiting) {
        if (this.state !== 'none') {
          var pageStates = ['finished', 'joined'];
          if (pageStates.includes(this.state)) return this.coursePageLink;
        }
        if (this.defLink == 'page') return this.coursePageLink;
      }
      return this.courseLandingLink || this.coursePageLink;
    },
    reportCardStore: function reportCardStore() {
      return useReportCardStore();
    },
    card: function card() {
      return this.reportCardStore.getCourseCard(this.course);
    },
    modules: function modules() {
      return this.reportCardStore.getCourseModules(this.course);
    },
    currentModule: function currentModule() {
      var _this = this;
      if (!this.card || this.card.certificateId) return null;
      if (!this.modules || this.modules.length == 0) return null;
      var i = 0,
        len = this.modules.length;
      for (; i < len; i++) {
        var result = this.card.modules.find(function (o) {
          return _this.modules[i].id == o.module;
        });
        if (!result || !result.passed) break;
      }
      if (i >= len) i = len - 1;
      return this.modules[i];
    },
    currentModuleLink: function currentModuleLink() {
      var mod = this.currentModule;
      if (!mod) return null;
      return "/creativepractice/course/".concat(this.course.slug, "/module/").concat(mod.slug);
    },
    progress: function progress() {
      if (!this.card) return 0;
      var passed = 0;
      var modulesLength = 0;
      var hiddenIds = [];
      this.course.program.forEach(function (o) {
        if (!o.hidden) modulesLength++;else hiddenIds.push(o.module);
      });
      if (this.card.modules) {
        this.card.modules.forEach(function (o) {
          if (o.passed && hiddenIds.indexOf(o.module) < 0) passed++;
        });
      }
      return Math.round(passed / modulesLength * 100);
    }
  },
  methods: {
    archive: function archive() {
      this.reportCardStore.setStatus({
        status: 'archived',
        id: this.card.id
      });
      this.$modalityStore.closeDropdown({
        name: "course-menu-".concat(this.course.id)
      });
    },
    restore: function restore() {
      this.reportCardStore.setStatus({
        status: 'active',
        id: this.card.id
      });
      this.$modalityStore.closeDropdown({
        name: "course-menu-".concat(this.course.id)
      });
    },
    levelColor: function levelColor(level) {
      if (level == 'beginner') return 'success';
      if (level == 'intermediate') return 'yellow';
      if (level == 'advanced') return 'purple';
      return 'error';
    }
  }
};